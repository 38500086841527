import AccessControlSpecsPage from "components/access-control/specs/AccessControlSpecsPage";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import useIsMobile from "hooks/useIsMobile";
import React from "react";
import Helmet from "react-helmet";

export default function DC20() {
  const { isMobile } = useIsMobile(700);
  let exploreDR40Width = "51px";
  let exploreDR20Width = "48px";

  if (isMobile) {
    exploreDR40Width = "41px";
    exploreDR20Width = "38px";
  }

  const renderContent = data => {
    const dc20 = {
      title: "DC20",
      imageHeader: data.dc20Header,
      imageHeaderMobile: data.dc20HeaderMobile,
      objectPosition: isMobile ? "center" : "top",
      description:
        "Cloud-managed, secure by default, and built for enterprise scalability, the DC20 pairs seamlessly with Rhombus Access Control devices and existing door hardware. ",
      image: data.dc20Dims,
      pdf: "/files/specs/DC20.pdf",
      manual: "/files/manuals/DC20-Manual.pdf",
      column1: [
        {
          title: "Dimensions",
          text: ["330mm (13.0in) x 313 mm (12.3in) x 113mm (4.4in)"],
        },
        {
          title: "Weight",
          text: [
            "13.0 lb (5.9 kg) (Enclosure + internal parts, excluding batteries)",
          ],
        },
        {
          title: "AC Power Input",
          text: "100-240VAC, 50-60Hz 90W Max",
        },
        {
          title: "Relays",
          text: "4 Door Relays, 2 Aux Relays",
        },
        {
          title: "Inputs",
          text: ["4 REX and 4 DPI (Supervised)", "1 AUX input (Unsupervised)"],
        },
      ],
      column2: [
        {
          title: "Operating Temperature",
          text: "32˚F - 122˚F (0˚C - 50˚C)",
        },
        {
          title: "Connectivity",
          text: "Ethernet 10 / 100Mbps RJ45",
        },
        {
          title: "In the Box",
          text: "2 enclosure keys, mounting screws, user manual",
        },
        {
          title: "Mounting",
          text: "Wood / drywall screws",
        },
        {
          title: "10-Year Warranty Included",
        },
      ],
      exploreCards: [
        {
          image: data.exploreDR20,
          alt: "DR20 Access Control Door Reader",
          width: exploreDR20Width,
          model: "DR20",
          useCase: "Modern, touchless, and reliable smart reader",
          link: "/access-control/door-readers/dr20/",
        },
        {
          image: data.exploreDR40,
          alt: "DR40 Access Control Door Reader",
          width: exploreDR40Width,
          model: "DR40",
          useCase: "Equipped with a door reader, camera, and intercom",
          link: "/access-control/door-readers/dr40/",
        },
      ],
    };

    return (
      <GlobalLayout color="transparent" dark>
        <Helmet>
          <title>Rhombus DC20 Access Control 4-Door Access Control Unit</title>
          <meta
            name="description"
            content="Pair with existing door hardware for easy cloud-managed, secure by default, and built-for enterprise scalability access control."
          />
        </Helmet>
        <AccessControlSpecsPage model={dc20} />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      dc20Header: file(
        relativePath: {
          eq: "components/access-control/specs/img/dc20-product-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dc20HeaderMobile: file(
        relativePath: {
          eq: "components/access-control/specs/img/dc20-product-header.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      dc20Dims: file(
        relativePath: {
          eq: "components/access-control/specs/img/dc20-dimensions-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR20: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr20.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
      exploreDR40: file(
        relativePath: {
          eq: "components/access-control/specs/img/explore-dr40.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: BLURRED)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
